import axiosInstance from "../config/api";

export interface Classroom {
  id: any;
  name: string;
  description: string;
  size: number;
  pricePerHour: number;
  from: Date;
  to: Date;
  location: { lat: any; lng: any };
  address: {
    area: any;
    city: any;
    name: any;
    state: any;
  };
  logo: {
    container: string;
    name: string;
    type: string;
    field: string;
    originalFilename: string;
    size: number;
  } | null;
  ownerName: string;
  album: { name: string; originalFilename: string }[];
  available: boolean;
  equipments: any[];
  typeEquipment: object;
  deleted: boolean;
  status: string;
  rating: number;
  ratingsCount: number;
  isOnline: boolean;
}

export async function getBookingList(
  id: string,
  limit: number,
  skip: number,
  tab: number
): Promise<any | null> {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.get(
      `/Bookings/list?limit=${limit}&skip=${skip}&tab=${tab}&hostId=${id}&access_token=${token}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getMyList(
  limit: number,
  skip: number,
  tab: number
): Promise<any | null> {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.get(
      `/Rooms/getMyList?limit=${limit}&skip=${skip}&tab=${tab}&access_token=${token}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function changeClassroomPrivacy(
  id: string,
  value: boolean
): Promise<any | null> {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.post(
      `/Rooms/displayMask?roomId=${id}&value=${value}&access_token=${token}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function newClassroom(classroom: object): Promise<any | null> {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.post(
      `/Rooms?access_token=${token}`,
      classroom
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getAvailableClassrooms(
  isOnline: boolean,
  limit: number,
  skip: number,
  start: string,
  end: string,
  startTime: string,
  endTime: string
): Promise<any | null> {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.get(
      `/Rooms/list?isOnline=${isOnline}&start=${start}&end=${end}&startTime=${startTime}&endTime=${endTime}&limit=${limit}&skip=${skip}&access_token=${token}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function acceptRefuseHosting(
  sessionId: string,
  roomId: string,
  value: boolean
): Promise<any | null> {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.post(
      `/Bookings/acceptRefuse?sessionId=${sessionId}&roomId=${roomId}&value=${value}&access_token=${token}`
    );
    return response.data;
  } catch (error) {
    console.log("error", error);
    return null;
  }
}

export async function getPublicRooms(): Promise<any | null> {
  try {
    const response = await axiosInstance.get("/Rooms/publicRooms");

    return response.data;
  } catch (error) {
    console.log("errorr", error);
    return null;
  }
}

export async function editClassroom(
  data: Classroom
): Promise<Classroom | null> {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.patch(
      `/Rooms/${data.id}?access_token=${token}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error updating classroom:", error);
    throw error;
  }
}

export async function getClassroomDetails(
  id: string
): Promise<Classroom | null> {
  const token = localStorage.getItem("token");
  try {
    const response = await axiosInstance.get(
      `/Rooms/details/${id}?access_token=${token}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching classroom details:", error);
    return null;
  }
}
