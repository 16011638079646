import axiosInstance from "../config/api";
import { makeAutoObservable, runInAction } from "mobx";

type Country = {
  id: number;
  name: string;
  nameAr: string;
};

type State = {
  id: number;
  name: string;
  country_id: number;
  country_code: string;
  country_name: string;
  state_code: string;
  type: string | null;
  latitude: string;
  longitude: string;
};

export class LocationStore {
  countries: Country[] = [];
  states: State[] = [];
  

  constructor() {
    makeAutoObservable(this);
  }

  async fetchCountries() {
    try {
      const response = await axiosInstance.get("/Countries");
      if (response.status === 200) {
        runInAction(() => {
          this.countries = response.data;
          localStorage.setItem("countries", JSON.stringify(this.countries));
        });
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  }

  async fetchStates() {
    try {
      const response = await axiosInstance.get("/States");
      if (response.status === 200) {
        runInAction(() => {
          this.states = response.data;
          localStorage.setItem("states", JSON.stringify(this.states));
        });
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  }

  async initializeLocationData() {
    const countriesList = localStorage.getItem("countries");
    const statesList = localStorage.getItem("states");
    const languageStorage = localStorage.getItem("language")
    if(!languageStorage){
      localStorage.setItem("language", "English")
    }
    if (countriesList && statesList) {
      runInAction(() => {
        this.countries = JSON.parse(countriesList);
        this.states = JSON.parse(statesList);
      });
    } else {
      await this.fetchCountries();
      await this.fetchStates();
    }
  }

  async getUserLocation() {
    try {
      const response = await axiosInstance.get("/Profiles/get-location");
      if (response.data.data && response.data.data.country) {
        localStorage.setItem("country", response.data.data.country);
      }
    } catch (error) {
      console.log("errorr", error);
    }
  }
}
