import { Navigate, createBrowserRouter } from "react-router-dom";
import React, { Suspense } from "react";
import { Loading } from "./components/shared";
import MyProfile from "./screens/authed/MyProfile";
import HostUpgrade from "./screens/authed/HostUpgrade";

const ProtectedRoute = React.lazy(() => import("./store/ProtectedRoute"));
const Home = React.lazy(() => import("./screens/root/Home"));
const Courses = React.lazy(() => import("./screens/root/Courses"));
const Course = React.lazy(() => import("./screens/root/Course"));
const Cohort = React.lazy(() => import("./screens/root/Cohort"));
const Payment = React.lazy(() => import("./screens/authed/payment"));
const Enrolled = React.lazy(() => import("./screens/authed/Enrolled"));
const PostWirePaymentSuccess = React.lazy(
  () => import("./screens/authed/postWirePaymentSuccess")
);
const Verifcation = React.lazy(() => import("./screens/auth/Verifcation"));
const Logine = React.lazy(() => import("./screens/auth/Login1/index1"));
const Register = React.lazy(() => import("./screens/auth/Register"));
const CompleteRegister = React.lazy(
  () => import("./screens/auth/CompleteRegister")
);
const ChoosePlan = React.lazy(() => import("./screens/auth/ChoosePlan"));
const InstructorUpgrade = React.lazy(
  () => import("./screens/auth/InstructorUpgrade")
);
const NewSession = React.lazy(() => import("./screens/authed/NewSession"));
const ClassroomsManagement = React.lazy(
  () => import("./screens/authed/ClassroomsManagement")
);
const NewClassroom = React.lazy(() => import("./screens/authed/NewClassroom"));
const Rate = React.lazy(() => import("./screens/authed/Rate"));
const Favorites = React.lazy(() => import("./screens/authed/Favorites"));
const PaymentFailed = React.lazy(
  () => import("./screens/authed/payment/PaymentFailed")
);
const EditClassroom = React.lazy(
  () => import("./screens/authed/EditClassroom/EditClassroom")
);
const ForgotPassword = React.lazy(
  () => import("./screens/auth/forgotPassword")
);
const ResetPassword = React.lazy(() => import("./screens/auth/resetPassword"));
const MailVerification = React.lazy(
  () => import("./screens/auth/MailVerification")
);
const ResetPassword1 = React.lazy(
  () => import("./screens/auth/resetPassword1")
);
const ResetPasswordSuccess = React.lazy(
  () => import("./screens/auth/ResetPasswordSuccess")
);
const MySessions = React.lazy(() => import("./screens/authed/MySessions"));
const NewCourse = React.lazy(() => import("./screens/authed/NewCourse"));
const UpdateCourse = React.lazy(() => import("./screens/authed/UpdateCourse"));
const Teaching = React.lazy(() => import("./screens/authed/Teaching"));

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/search",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <Courses />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/courses",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <Courses />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/course/:id",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <Course />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/class/:id",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <Cohort />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/payment/:id",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <Payment />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/enrolled",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <Enrolled />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/successfully-enrolled",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <PostWirePaymentSuccess />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/user/validation",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <Verifcation />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/login",
    element: (
      <Suspense fallback={<Loading />}>
        <Logine />
      </Suspense>
    ),
  },

  {
    path: "/register",
    element: (
      <Suspense fallback={<Loading />}>
        <Register />
      </Suspense>
    ),
  },
  {
    path: "/complete-register",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <CompleteRegister />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/choose-plan",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <ChoosePlan />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/instructorUpgrade",
    element: (
      <Suspense fallback={<Loading />}>
        <InstructorUpgrade />
      </Suspense>
    ),
  },
  {
    path: "/hostUpgrade",
    element: (
      <Suspense fallback={<Loading />}>
        <HostUpgrade />
      </Suspense>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <ForgotPassword />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/user/forgotPassword/reset",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <ResetPassword />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/mail-verification",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <MailVerification />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/reset-password",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <ResetPassword1 />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/success-reset-password",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <ResetPasswordSuccess />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/myprofile",
    element: (
      <ProtectedRoute>
        <MyProfile />
      </ProtectedRoute>
    ),
  },
  {
    path: "/myclasses",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <MySessions />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/teaching",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <Teaching />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/newCourse",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <NewCourse />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/updateCourse/:id",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <UpdateCourse />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/add-class/:type/:id?",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <NewSession />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/classroomsManagement",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <ClassroomsManagement />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/newClassroom",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <NewClassroom />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/editClassroom/:id",
    element: (
      <Suspense fallback={<Loading />}>
        <EditClassroom />
      </Suspense>
    ),
  },
  {
    path: "/rate/:id",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <Rate />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/favorites",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <Favorites />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/paymentFailed/:sessionId",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <PaymentFailed />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "*",
    element: (
      <Suspense fallback={<Loading />}>
        <ProtectedRoute>
          <Navigate to="/" />
        </ProtectedRoute>
      </Suspense>
    ),
  },
]);

export default router;
